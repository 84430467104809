import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { server } from "server";
import { toast } from "react-toastify";

const Transactions = () => {
  const [customerName, setCustomerName] = useState("");
  const [products, setProducts] = useState([]);
  const [invoiceDate, setInvoiceDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const [paymentMethod, setPaymentMethod] = useState(""); // New state for payment method
  const [userId, setUserId] = useState(null);
  const [tableData, setTableData] = useState([
    { id: 1, nom: "",categorie: "", description: "", prix: 0, quantite: 0 },
  ]);

  const printRef = useRef();

  const handleChange = (setter) => (event) => setter(event.target.value);

  const handleAddRow = () => {
    setTableData([
      ...tableData,
      {
        id: tableData.length + 1,
        nom: "",
        categorie: "",
        description: "",
        prix: 0,
        quantite: 0,
      },
    ]);
  };

  const handleDeleteRow = (rowIndex) => {
    if (tableData.length > 1) {
      setTableData(tableData.filter((_, index) => index !== rowIndex));
    }
  };

  const handleRowChange = (rowIndex, key) => (event) => {
    const value = parseFloat(event.target.value) || 0;
    const updatedData = [...tableData];
    updatedData[rowIndex][key] = value;
    setTableData(updatedData);
  };

  useEffect(() => {
    const getUserIdFromStorage = async () => {
      const storedUserId = localStorage.getItem("secretKeyesforzado7");
      if (storedUserId) {
        setUserId(storedUserId);
      }
    };

    getUserIdFromStorage();
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await axios.get(`${server}/product/get-all-products`);
        setProducts(response.data.allproducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, []);

  const handleProductSelection = (rowIndex) => async (event) => {
    const updatedData = [...tableData];
    const selectedProductId = event.target.value;
    updatedData[rowIndex].item = selectedProductId;

    try {
      const response = await axios.get(
        `${server}/product/a-product/${selectedProductId}`
      );
      const product = response.data.products;
      updatedData[rowIndex].prix = product.price_vente;
      updatedData[rowIndex].categorie = product.category;
      updatedData[rowIndex].description = product.desc;
      updatedData[rowIndex].nom = product.name;
      setTableData(updatedData);
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };

  const handleSaveSale = async () => {
    const formData = {
      userId,
      customerName,
      paymentMethod,
      products: tableData.map((item) => ({
        productId: item.item,
        price_vente: item.prix,
        quantity: item.quantite,
      })),
    };

    try {
      const response = await axios.post(
        `${server}/sells/api/ventes`,
        formData,
        { withCredentials: true }
      );
      if (response.data.success) {
        toast.success("Vente créée avec succès !");
        setTimeout(() => {
          window.location.replace("/ventes");
        }, 1000);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const calculateTotal = () => {
    return tableData.reduce((total, row) => total + row.prix * row.quantite, 0);
  };

  return (
    <div className="container">
      <div className="card">
        <div className="card-header text-center">
          <h4>Vente</h4>
        </div>
        <div className="card-body" ref={printRef}>
          <div className="row">
            <div className="col-md-8">
              <div className="input-group mb-3">
                <span className="input-group-text">Nom de Client</span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="client"
                  value={customerName}
                  onChange={handleChange(setCustomerName)}
                />
              </div>
            </div>
            <div className="col-md-4">
              <div className="input-group mb-3 input-group-date">
                <span className="input-group-text">Inv. Date</span>
                <input
                  type="date"
                  className="form-control"
                  value={invoiceDate}
                  onChange={handleChange(setInvoiceDate)}
                />
              </div>
            </div>
            <div className="col-md-3">
              <div className="input-group mb-3">
                <span className="input-group-text">Methode Payaiment</span>
                <select
                  className="form-control"
                  value={paymentMethod}
                  onChange={handleChange(setPaymentMethod)}
                >
                  <option value="">
                    --Selectionner la methode de payment--
                  </option>

                  <option value="CASH">CASH</option>
                  <option value="KCB">KCB</option>
                  <option value="BANCOBU">BANCOBU</option>
                  <option value="IBB">IBB</option>
                  <option value="BCB">BCB</option>
                </select>
              </div>
            </div>
          </div>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Nom du produit</th>
                <th scope="col" className="text-end">
                  Categorie
                </th>
                <th scope="col" className="text-end">
                  Description
                </th>
                <th scope="col" className="text-end">
                  Prix
                </th>
                <th scope="col" className="text-end">
                  Quantite
                </th>
                <th scope="col">
                  <button
                    type="button"
                    className="btn btn-sm btn-success"
                    onClick={handleAddRow}
                  >
                    +
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={row.id}>
                  <th scope="row">{index + 1}</th>
                  <td>
                    <select
                      className="form-control"
                      value={row.item}
                      onChange={handleProductSelection(index)}
                    >
                      <option value="">--Select Product--</option>
                      {products.map((product) => (
                        <option key={product._id} value={product._id}>
                          {product.name}
                        </option>
                      ))}
                    </select>
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={row.categorie}
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control text-end"
                      value={row.description}
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control text-end"
                      value={row.prix}
                      disabled
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      className="form-control text-end"
                      value={row.quantite}
                      onChange={handleRowChange(index, "quantite")}
                    />
                  </td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      onClick={() => handleDeleteRow(index)}
                    >
                      X
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="row">
            <div className="col-md-8">
              <button
                type="button"
                className="btn btn-primary NoPrint"
                onClick={handleSaveSale}
              >
                Vendre
              </button>
            </div>
            <div className="col-md-4">
              <div className="input-group mb-3">
                <span className="input-group-text">Total</span>
                <input
                  type="number"
                  className="form-control text-end"
                  value={calculateTotal()}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Transactions;
