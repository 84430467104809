import React, { useEffect, useState } from "react";
import { Box, Button, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import DataGridVenteToolbar from "components/DataGridVenteToolbar";
import { server } from "server";
import axios from "axios";
import pdfPicture from "../../assets/pdf-svgrepo-com.svg";
import { toast } from "react-toastify";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Logo from "../../assets/ILLUSTRATION LOGO BISMOS .png";
import { CancelPresentationSharp, CheckBox, DeleteOutline } from "@mui/icons-material";
import PopupConfirm from "components/PopupConfirm";
import FlexBetween from "components/FlexBetween";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "state/api";

const Sells = () => {
  const theme = useTheme();

  // values to be sent to the backend
  const [openDelete, setOpenDelete] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [Id, setId] = useState(false);
  const [sales, setSales] = useState([]);
  const userId = useSelector((state) => state.global.userId);
  const { data } = useGetUserQuery(userId);

  useEffect(() => {
    axios.get(`${server}/sells/get-all-sales`).then((res) => {
      setSales(res.data.allSales);
    });
  }, []);

  const handleDelete = (id) => {
    axios
      .delete(`${server}/sells/delete-sale/${id}`)
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload();
      })
      .catch((err) => {
        toast.error(err);
      });
  };


  const generatePDF = async (id) => {
    try {
      // Fetch sale data from server
      const response = await axios.get(`${server}/sells/get-sale/${id}`);
      const sale = response.data.Sale;

      // Initialize jsPDF document with specified properties
      const doc = new jsPDF({
        orientation: "portrait",
        unit: "mm",
        format: "a4",
      });

      // Extract necessary data from sale object
      const {
        nomClient,
        products,
        totalPrice,
        paymentMethod,
        createdAt,
        invoiceNumber,
      } = sale;

      // Load your logo image (replace Logo with your actual image variable)
      const imgData = Logo; // Replace with your base64 encoded logo image or imported image variable

      // Add logo at the top left corner
      if (imgData) {
        doc.addImage(imgData, "PNG", 15, 15, 30, 30);
      }

      // Title and Company Info
      doc.setFontSize(22);
      doc.text("Facture", 105, 25, null, null, "center");

      // Company information
      doc.setFontSize(12);
      doc.text(`Nom de l'entreprise: Bismos High Tech`, 15, 60);
      doc.text("NIF: 400045190 / RC: 03436", 15, 65);
      doc.text("Avenue: KIGARAMA, Ngozi N0: 21", 15, 70);
      doc.text("Centre Fiscal: Ressort Nord", 15, 75);
      doc.text("Secteur d'activité: COM. General", 15, 80);
      doc.text("Forme Juridique: Personne Morale", 15, 85);

      // Invoice details
      doc.text(`N0 du facture:${invoiceNumber} `, 150, 60);
      doc.text(`Fait le:${createdAt?.slice(0, 10)}`, 150, 65);

      // Payment method and customer name

      doc.text(`Méthode de paiement:`, 15, 90);
      doc.text(`${paymentMethod}`, 60, 90);
      doc.text(`Nom du client:`, 15, 95);
      doc.text(`${nomClient}`, 60, 95);

      // Add checkboxes below Numero de contact
      const checkboxY = 95 + 5;
      doc.setFontSize(10);
      doc.text("assujetti à la TVA : ", 15, checkboxY + 2);
      doc.rect(42, checkboxY - 2, 5, 5); // Checkbox 1
      doc.text("Oui", 47, checkboxY + 2);
      doc.rect(54, checkboxY - 2, 5, 5); // Checkbox 2
      doc.text("Non", 59, checkboxY + 2);

      // Table of Products
      const tableY = checkboxY + 5;
      const tableHeaders = [
        "Produit",
        "Vategorie",
        "Description",
        "Quantité",
        "Prix unitaire",
        "Prix Total",
      ];

      doc.autoTable({
        startY: tableY,
        head: [tableHeaders],
        body: products.map((product) => [
          product.productName,
          product.category,
          product.desc,
          product.quantity,
          `${product.price} Fbu`,
          `${product.ProductTotal} Fbu`,
        ]),
        theme: "plain",
        headStyles: {
          fillColor: [41, 128, 185],
          textColor: 255,
        },
        bodyStyles: {
          textColor: 50,
        },
        columnStyles: {
          0: { fontStyle: "bold" },
        },
        margin: { top: 10 },
      });

      // Total
      const totalY = doc.autoTable.previous.finalY + 10;
      doc.setFontSize(12);
      doc.text(`Prix Total: ${totalPrice} Fbu`, 150, totalY);

      const footerY = doc.internal.pageSize.getHeight() - 20;
      const footerText =
        "Tel: 79 967 624 / 22303165 ||KCB:06691079043 ,Bancobu :20002-11109-06571220101-09";
      const footerText2 = "www.bismos.com || E-mail: info@bismos.com";
      doc.setFont("helvetica", "normal");
      doc.text(footerText, doc.internal.pageSize.getWidth() / 2, footerY, {
        align: "center",
      });
      doc.text(footerText2, doc.internal.pageSize.getWidth() / 2, footerY + 5, {
        align: "center",
      });

      // Save the PDF
      const filename = `facture_${invoiceNumber}.pdf`; // Filename includes invoice number
      doc.save(filename);

      // Show success toast
      toast.success("Le PDF a été créé avec succès");
    } catch (error) {
      // Handle errors
      console.error("Error generating PDF:", error);
      toast.error("Erreur lors de la génération du PDF");
    }
  };

  const columns = [
    {
      field: "createdAt",
      headerName: "créer du:",
      flex: 1,
    },
    {
      field: "nomClient",
      headerName: "Nom du client",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "name",
      headerName: "Effectué par",
      flex: 1,
      cellClassName: "name-column--cell",
    },

    {
      field: "action",
      headerName: "Facturation",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => generatePDF(params.row.id)}
            >
              <img
                src={pdfPicture}
                alt=""
                style={{
                  width: "28px",
                  height: " 28px",
                  marginRight: " 10px",
                  transition: "transform 0.3s",
                }}
              />
              
            {data?.role === "superadmin" && (
              <DeleteOutline
                className="userListDelete"
                onClick={() =>
                  setId(params.row.id) || setOpenDelete(!openDelete)
                }
              />
            )}
            </div>
          </>
        );
      },
    },
  ];
  const row = [];

  sales &&
    sales.forEach((item) => {
      row.push({
        id: item._id,
        url: item.url,
        name: item.username,
        nomClient: item.nomClient,
        createdAt: item.createdAt.slice(0, 10),
      });
    });

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Ventes" subtitle="Liste des Produits vendus" />
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={row}
          columns={columns}
          components={{ Toolbar: DataGridVenteToolbar }}
          componentsProps={{
            toolbar: { openPopup, setOpenPopup },
          }}
        />
      </Box>
      <PopupConfirm
        title="Voulez-vous supprimer le produit "
        open={openDelete}
        setOpen={setOpenDelete}
      >
        <FlexBetween>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "1px" }}
            onClick={() => setOpenDelete(!openDelete) || handleDelete(Id)}
          >
            <CheckBox />
            OUI
          </Button>
          <Button
            color="secondary"
            variant="contained"
            style={{ marginBottom: "1px" }}
            onClick={() => setOpenDelete(!openDelete)}
          >
            <CancelPresentationSharp />
            Non
          </Button>
        </FlexBetween>
      </PopupConfirm>
    </Box>
  );
};

export default Sells;
