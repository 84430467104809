import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  useTheme,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Header from "components/Header";
import DataGridVenteToolbar from "components/DataGridVenteToolbar";
import axios from "axios";
import pdfPicture from "../../assets/pdf-svgrepo-com.svg";
import { server, backend_url } from "server";
import { toast } from "react-toastify";
import FileSaver from "file-saver";
import FlexBetween from "components/FlexBetween";

const Sells = () => {
  const theme = useTheme();
  const [openPopup, setOpenPopup] = useState(false);
  const [sales, setSales] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleDateChange = (event) => {
    const date = event.target.value;
    setSelectedDate(date);
    const [year, month] = date.split("-");
    fetchCommandesByMonth(year, month);
  };
  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    fetchCommandesByMonth(year, month);
  }, []);
  const formatNumber = (number) => {
    return number.toLocaleString();
  };
  const fetchCommandesByMonth = async (year, month) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${server}/sells/sales-by-month/${year}/${month}`
      );
      setSales(response.data.sales);
      setLoading(false);
    } catch (error) {
      toast.error("Ces données ne sont pas disponibles");
      setError("Ces données ne sont pas disponibles");
      setLoading(false);
    }
  };
  const columns = [
    {
      field: "createdAt",
      headerName: "créer du:",
      flex: 1,
    },
    {
      field: "nomClient",
      headerName: "Nom du client",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "name",
      headerName: "Effectué par",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "Total",
      headerName: "Montant Total",
      flex: 2,
      cellClassName: "name-column--cell",
    },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <a
              href={`${backend_url}${params.row.url}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={pdfPicture}
                alt=""
                style={{
                  width: "28px",
                  height: "28px",
                  marginRight: "10px",
                  transition: "transform 0.3s",
                }}
              />
            </a>
          </>
        );
      },
    },
  ];

  const row = [];
  sales &&
    sales.forEach((item) => {
      row.push({
        id: item._id,
        url: item.url,
        name: item.username,
        Total: formatNumber(item.totalPrice),
        nomClient: item.nomClient,
        createdAt: item.createdAt.slice(0, 10),
      });
    });

  return (
    <Box m="1.5rem 2.5rem">
      <Header title="Ventes Mensuelles" subtitle="Liste des Produits vendus" />
      <FlexBetween>
        <TextField
          id="date"
          label="Select Month"
          type="month"
          value={selectedDate}
          onChange={handleDateChange}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </FlexBetween>
      <Box
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: theme.palette.primary.light,
          },
          "& .MuiDataGrid-footerContainer": {
            backgroundColor: theme.palette.background.alt,
            color: theme.palette.secondary[100],
            borderTop: "none",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.secondary[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={row}
          columns={columns}
          components={{ Toolbar: DataGridVenteToolbar }}
          componentsProps={{
            toolbar: { openPopup, setOpenPopup },
          }}
        />
      </Box>
    </Box>
  );
};

export default Sells;
